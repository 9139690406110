export const donorGridItemFrag = `
    id
    mergedWith
    name
    emails {
        email
        active
    }
    type
    bankAccounts {
        iban
        active
    }
    donationReport
    specificSymbols {
        specificSymbol
        active
    }
    addresses {
        street
    }
    phones {
        phone
        active
    }
    sendDonationReceiptByMail
    sendDonationReceipt
    sendNewsletter
    specialAttention
    isImportant
    contactManager
    contactManagerInfo {
        name
        surname
        email
        titleBeforeName
        titleAfterName
    }
    contactManagerGroupInfo {
        id
        name
    }
    organizationId
    donationCount
    totalDonated
    collectionCategories
    firstDonation {
        id
        receivedAt
        donationFormName
        collectionName
    }
`;

export const donorGridItemDetailFrag = `
    id
    mergedWith
    name
    emails {
        email
        active
        note
    }
    type
    bankAccounts {
        iban
        active
        note
    }
    donationReport
    specificSymbols {
        specificSymbol
        active
        note
    }
    addresses {
        street
        city
        zip
        active
    }
    phones {
        phone
        active
        note
    }
    sendDonationReceiptByMail
    sendDonationReceipt
    sendNewsletter
    sendTransactionalMail
    specialAttention
    isImportant
    contactManager
    contactManagerGroup
    contactManagerInfo {
        name
        surname
        email
        titleBeforeName
        titleAfterName
    }
    contactManagerGroupInfo {
        id
        name
    }
    contactRepresentative {
        name
        surname
        titlesBefore
        titlesAfter
        company
        birthdate
        fullName
    }
    contactRepresentativeIco
    contactRepresentativeDic
    contactRepresentativeStreet
    contactRepresentativeCity
    contactRepresentativeZip
    details {
        name
        surname
        titlesBefore
        titlesAfter
        company
        birthdate
    }
    ico
    dic
    createdAt
    createdBy
    updatedAt
    firstDonation {
        receivedAt
    }
    lastDonation
    categories {
        id
        category
        manual
    }
    salesforceContactId
    salesforceParentAccountId
    seContactId
    comment
    donationCount
    totalDonated
    regularity
    regularityManual
    sentEmails {
        path
        date
        kind
    }
    organizationId
    contactSource
`;

export const donorGridItemSearchFrag = `
    id
    name
    emails {
        email
        active
        note
    }
    createdAt
    comment
`;

export const donorStatisticsFrag = `
    collections {
        name
        lastYear
        lastYearSum
        last3Year
        last3YearSum
        last5Year
        last5YearSum
    }
    categories {
        name
        total
        totalSum
        year
        yearSum
        thisYear
        thisYearSum
        lastYear
        lastYearSum
        last3Year
        last3YearSum
    }
`;
